/**
 * @generated SignedSource<<2f3384272f724ecbff5d8f65b05dbb1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BridgeOrWrapFormQuery$variables = {
  address: string;
  baseCurrencyChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "B3" | "%future added value";
  baseCurrencyIsChainNativeCurrency: boolean;
  baseCurrencySymbol: string;
  wrappedCurrencyChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "B3" | "%future added value";
  wrappedCurrencySymbol: string;
};
export type BridgeOrWrapFormQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BridgeOrWrapFormMaxButton_data">;
};
export type BridgeOrWrapFormQuery = {
  response: BridgeOrWrapFormQuery$data;
  variables: BridgeOrWrapFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseCurrencyChain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseCurrencyIsChainNativeCurrency"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseCurrencySymbol"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wrappedCurrencyChain"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wrappedCurrencySymbol"
},
v6 = {
  "kind": "Variable",
  "name": "address",
  "variableName": "address"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BridgeOrWrapFormQuery",
    "selections": [
      {
        "args": [
          (v6/*: any*/),
          {
            "kind": "Variable",
            "name": "baseCurrencyChain",
            "variableName": "baseCurrencyChain"
          },
          {
            "kind": "Variable",
            "name": "baseCurrencyIsChainNativeCurrency",
            "variableName": "baseCurrencyIsChainNativeCurrency"
          },
          {
            "kind": "Variable",
            "name": "baseCurrencySymbol",
            "variableName": "baseCurrencySymbol"
          },
          {
            "kind": "Variable",
            "name": "wrappedCurrencyChain",
            "variableName": "wrappedCurrencyChain"
          },
          {
            "kind": "Variable",
            "name": "wrappedCurrencySymbol",
            "variableName": "wrappedCurrencySymbol"
          }
        ],
        "kind": "FragmentSpread",
        "name": "BridgeOrWrapFormMaxButton_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "BridgeOrWrapFormQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v6/*: any*/)
        ],
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "condition": "baseCurrencyIsChainNativeCurrency",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "baseCurrencyFunds",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "chain",
                    "variableName": "baseCurrencyChain"
                  },
                  {
                    "kind": "Variable",
                    "name": "symbol",
                    "variableName": "baseCurrencySymbol"
                  }
                ],
                "concreteType": "WalletFundsType",
                "kind": "LinkedField",
                "name": "fundsOf",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "alias": "wrappedCurrencyFunds",
            "args": [
              {
                "kind": "Variable",
                "name": "chain",
                "variableName": "wrappedCurrencyChain"
              },
              {
                "kind": "Variable",
                "name": "symbol",
                "variableName": "wrappedCurrencySymbol"
              }
            ],
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8928549e44ea7c46824afd739ff3db6",
    "id": null,
    "metadata": {},
    "name": "BridgeOrWrapFormQuery",
    "operationKind": "query",
    "text": "query BridgeOrWrapFormQuery(\n  $address: AddressScalar!\n  $wrappedCurrencySymbol: String!\n  $wrappedCurrencyChain: ChainScalar!\n  $baseCurrencySymbol: String!\n  $baseCurrencyChain: ChainScalar!\n  $baseCurrencyIsChainNativeCurrency: Boolean!\n) {\n  ...BridgeOrWrapFormMaxButton_data_3Sfqau\n}\n\nfragment BridgeOrWrapFormMaxButton_data_3Sfqau on Query {\n  wallet(address: $address) {\n    baseCurrencyFunds: fundsOf(symbol: $baseCurrencySymbol, chain: $baseCurrencyChain) @skip(if: $baseCurrencyIsChainNativeCurrency) {\n      quantity\n      id\n    }\n    wrappedCurrencyFunds: fundsOf(symbol: $wrappedCurrencySymbol, chain: $wrappedCurrencyChain) {\n      quantity\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "afb2e68106b24af802473f78ca8fec01";

export default node;
