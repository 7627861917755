import { Address } from "viem"
import {
  arbitrum,
  arbitrumGoerli,
  arbitrumNova,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  base,
  baseGoerli,
  baseSepolia,
  blast,
  blastSepolia,
  bsc,
  bscTestnet,
  klaytn,
  klaytnBaobab,
  mainnet,
  optimism,
  optimismGoerli,
  optimismSepolia,
  polygon,
  polygonAmoy,
  polygonMumbai,
  seiDevnet,
  sepolia,
  zora,
  zoraSepolia,
  zoraTestnet,
  b3Sepolia,
  seiTestnet,
  b3,
} from "viem/chains"

const GUNZILLA_TESTNET = {
  id: 49_321,
  name: "GUNZ Testnet",
  nativeCurrency: { name: "GUN", symbol: "GUN", decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        "https://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/rpc",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Gunzscan",
      url: "https://testnet.gunzscan.io/",
    },
  },
  testnet: true,
}

const SEI_MAINNET = {
  id: 1329,
  name: "Sei",
  nativeCurrency: { name: "Sei", symbol: "SEI", decimals: 18 },
  rpcUrls: { default: { http: ["https://evm-rpc.sei-apis.com/"] } },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
  contracts: {
    multicall3: {
      address: "0xcb2436774C3e191c85056d248EF4260ce5f27A9D" as Address,
    },
  },
}

export const chains = [
  // Mainnet
  mainnet,
  polygon,
  klaytn,
  bsc,
  arbitrum,
  avalanche,
  optimism,
  arbitrumNova,
  base,
  zora,
  blast,
  b3,
  SEI_MAINNET,

  // Testnet
  sepolia,
  polygonMumbai,
  polygonAmoy,
  klaytnBaobab,
  bscTestnet,
  avalancheFuji,
  arbitrumGoerli,
  arbitrumSepolia,
  optimismGoerli,
  optimismSepolia,
  baseGoerli,
  baseSepolia,
  zoraTestnet,
  zoraSepolia,
  blastSepolia,
  seiDevnet,
  seiTestnet,
  b3Sepolia,
  GUNZILLA_TESTNET,
] as const
