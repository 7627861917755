import { Chain } from "@/hooks/useChains/types"
import { isEthereum, isPolygon, isBase } from "@/lib/helpers/chainUtils"
import { OPENSEA_LOGO_IMG, STATIC_ROOT } from "../constants"

export enum WALLET_NAME {
  Native = "Native",
  MetaMask = "MetaMask",
  Bitski = "Bitski",
  Phantom = "Phantom",
  Fortmatic = "Fortmatic",
  OperaTouch = "OperaTouch",
  Trust = "Trust",
  WalletConnect = "WalletConnect",
  LedgerConnect = "Ledger",
  CoinbaseWallet = "Coinbase Wallet",
  Kaikas = "Kaikas",
  BitKeep = "BitKeep",
  Core = "Core",
  OpenSeaWallet = "OpenSea",
  Zerion = "Zerion",
}

export enum WalletSupport {
  ANDROID = "android",
  IOS = "iOS",
  DESKTOP = "desktop",
}

const web3EvmWalletSupport = (chain: Chain) => {
  return chain.isEvm
}

const ledgerConnectChainSupport = (chain: Chain) => {
  return chain.identifier === "ETHEREUM"
}

const kaikasWalletSupport = (chain: Chain) => {
  return chain.identifier === "KLAYTN" || chain.identifier === "BAOBAB"
}

const solanaChainSupport = (chain: Chain) => {
  return chain.identifier === "SOLANA" || chain.identifier === "SOLDEV"
}

const phantomWalletSupport = (chain: Chain) => {
  return (
    solanaChainSupport(chain) ||
    isEthereum(chain.identifier) ||
    isPolygon(chain.identifier) ||
    isBase(chain.identifier)
  )
}

export type WalletConfiguration = {
  installLink?: string
  supports: WalletSupport[]
  logo: string
  alternativeLogo: string
  supportsChain: (chain: Chain) => boolean
}

const PHANTOM_LINK = "https://phantom.app/?utm_source=opensea"

const KAIKAS_URL =
  "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"

const METAMASK_URL = "https://metamask.io/download/"

export const METAMASK_MOBILE_URL = "https://metamask.app.link/dapp/"
export const OPERA_TOUCH_URL = "https://www.opera.com/mobile/touch"
export const PHANTOM_MOBILE_URL =
  "https://phantom.app/ul/browse/https%3A%2F%2Fopensea.io"
// 60 is for ETH. see https://github.com/satoshilabs/slips/blob/master/slip-0044.md
export const TRUST_WALLET_URL =
  "https://link.trustwallet.com/open_url?coin_id=60&url="

export const KAIKAS_MOBILE_URL = "https://app.kaikas.io/u/"

const METAMASK_LOGO = "/static/images/logos/metamask.png"
const METAMASK_ALTERNATIVE_LOGO = "/static/images/logos/metamask-fox.svg"
const LEDGERCONNECT_LOGO = "/static/images/logos/ledgerconnect.png"
const LEDGERCONNECT_ALTERNATIVE_LOGO =
  "/static/images/logos/ledgerconnect-alternative.png"
const PHANTOM_LOGO = `/static/images/logos/phantom.svg`
const PHANTOM_ALTERNATIVE_LOGO = `/static/images/logos/phantom.svg`
const BITSKI_LOGO = `${STATIC_ROOT}/logos/bitski.png`
const BITSKI_ALTERNATIVE_LOGO = `${STATIC_ROOT}/logos/bitski-alternative.png`

const WALLETCONNECT_LOGO = `${STATIC_ROOT}/logos/walletconnect.png`
const WALLETCONNECT_ALTERNATIVE_LOGO = `${STATIC_ROOT}/logos/walletconnect-alternative.png`
const OPERA_TOUCH_LOGO = "/static/images/logos/opera-touch.svg"
const OPERA_TOUCH_ALTERNATIVE_LOGO =
  "/static/images/logos/opera-touch-alternative.svg"

const TRUST_WALLET_LOGO = `${STATIC_ROOT}/logos/trustwallet-logo.png`
const TRUST_WALLET_ALTERNATIVE_LOGO = `${STATIC_ROOT}/logos/trust-alternative.png`
const COINBASE_WALLET_LOGO = `${STATIC_ROOT}/logos/coinbasewallet-logo.png`
const COINBASE_ALTERNATIVE_LOGO = `${STATIC_ROOT}/logos/walletlink-alternative.png`
const FORTMATIC_LOGO = `${STATIC_ROOT}/logos/fortmatic.png`
const FORTMATIC_ALTERNATIVE_LOGO = `${STATIC_ROOT}/logos/fortmatic-alternative.png`
const ZERION_lOGO = `/static/images/logos/zerion.svg`

const WALLET_CONFIGURATIONS: Record<WALLET_NAME, WalletConfiguration> = {
  [WALLET_NAME.Native]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    logo: "",
    alternativeLogo: "",
    supportsChain: () => false,
  },
  [WALLET_NAME.MetaMask]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    installLink: METAMASK_URL,
    logo: METAMASK_LOGO,
    alternativeLogo: METAMASK_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.CoinbaseWallet]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    logo: COINBASE_WALLET_LOGO,
    alternativeLogo: COINBASE_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.WalletConnect]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    logo: WALLETCONNECT_LOGO,
    alternativeLogo: WALLETCONNECT_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.LedgerConnect]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.IOS],
    logo: LEDGERCONNECT_LOGO,
    alternativeLogo: LEDGERCONNECT_ALTERNATIVE_LOGO,
    supportsChain: ledgerConnectChainSupport,
  },
  [WALLET_NAME.Phantom]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    installLink: PHANTOM_LINK,
    logo: PHANTOM_LOGO,
    alternativeLogo: PHANTOM_ALTERNATIVE_LOGO,
    supportsChain: phantomWalletSupport,
  },
  [WALLET_NAME.Zerion]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.IOS, WalletSupport.ANDROID],
    logo: ZERION_lOGO,
    alternativeLogo: ZERION_lOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.BitKeep]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    installLink: "https://bitkeep.com/en/download",
    logo: "https://cdn2.bitkeep.vip/web/pro-pc-project/img/BK%20logo_light.a2eebea.svg",
    alternativeLogo:
      "https://lh3.googleusercontent.com/uBaatYG20TOMFFUCTEgwtaI9Q6l_Nqr0qKUGQPJHjKnlLDqhwQaQbAvQku4nyH8TVxZKx96RsiFduLrcw2vqqjrr=w128-h128-e365-rj-sc0x00ffffff",
    // TODO: Implement Solana support
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.Kaikas]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    installLink: KAIKAS_URL,
    logo: "/static/images/logos/kaikas.png",
    alternativeLogo: "/static/images/logos/kaikas.png",
    supportsChain: kaikasWalletSupport,
  },
  [WALLET_NAME.Core]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    installLink:
      "https://chrome.google.com/webstore/detail/core/agoakfejjabomempkjlepdflaleeobhb",
    logo: "https://lh3.googleusercontent.com/uSYrwIYFZ-7kwg0-P_0YKubeNhFUN_jGg9J8bPbCOYjwW94jbFCEMqHUaV35sn0bvqujkuy6M72gjIRD4NTCirsQpXc=w128-h128-e365-rj-sc0x00ffffff",
    alternativeLogo:
      "https://lh3.googleusercontent.com/uSYrwIYFZ-7kwg0-P_0YKubeNhFUN_jGg9J8bPbCOYjwW94jbFCEMqHUaV35sn0bvqujkuy6M72gjIRD4NTCirsQpXc=w128-h128-e365-rj-sc0x00ffffff",
    supportsChain: web3EvmWalletSupport,
  },

  [WALLET_NAME.Fortmatic]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    logo: FORTMATIC_LOGO,
    alternativeLogo: FORTMATIC_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.Bitski]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    logo: BITSKI_LOGO,
    alternativeLogo: BITSKI_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },

  [WALLET_NAME.OperaTouch]: {
    supports: [WalletSupport.IOS, WalletSupport.ANDROID],
    logo: OPERA_TOUCH_LOGO,
    alternativeLogo: OPERA_TOUCH_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.Trust]: {
    supports: [WalletSupport.IOS, WalletSupport.ANDROID],
    logo: TRUST_WALLET_LOGO,
    alternativeLogo: TRUST_WALLET_ALTERNATIVE_LOGO,
    supportsChain: web3EvmWalletSupport,
  },
  [WALLET_NAME.OpenSeaWallet]: {
    supports: [WalletSupport.DESKTOP, WalletSupport.ANDROID, WalletSupport.IOS],
    logo: OPENSEA_LOGO_IMG,
    alternativeLogo: OPENSEA_LOGO_IMG,
    supportsChain: web3EvmWalletSupport,
  },
}

export const getWalletConfiguration = (
  walletName: WALLET_NAME,
): WalletConfiguration => {
  return WALLET_CONFIGURATIONS[walletName]
}

export const ALL_WALLETS = Object.keys(WALLET_CONFIGURATIONS).filter(
  walletName => walletName !== WALLET_NAME.Native,
) as WALLET_NAME[]
