import type { ChainIdentifier } from "@/hooks/useChains/types"
import { reverse } from "@/lib/helpers/object"

export const CHAIN_IDENTIFIER_ENUM_MAPPING: Record<ChainIdentifier, string> = {
  BAOBAB: "baobab",
  BASE: "base",
  BASE_GOERLI: "base-goerli",
  BASE_SEPOLIA: "base-sepolia",
  BLAST: "blast",
  BLAST_SEPOLIA: "blast-sepolia",
  GUNZILLA_TESTNET: "gunzilla-testnet",
  SEI: "sei",
  SEI_DEVNET: "sei-devnet",
  SEI_TESTNET: "sei-testnet",
  BSC: "bsc",
  BSC_TESTNET: "bsc-testnet",
  ETHEREUM: "ethereum",
  KLAYTN: "klaytn",
  MATIC: "matic",
  MUMBAI: "mumbai",
  AMOY: "amoy",
  RINKEBY: "rinkeby",
  GOERLI: "goerli",
  SOLANA: "solana",
  SOLDEV: "soldev",
  ARBITRUM: "arbitrum",
  ARBITRUM_NOVA: "arbitrum-nova",
  ARBITRUM_GOERLI: "arbitrum-goerli",
  ARBITRUM_SEPOLIA: "arbitrum-sepolia",
  AVALANCHE: "avalanche",
  AVALANCHE_FUJI: "avalanche-fuji",
  OPTIMISM: "optimism",
  OPTIMISM_GOERLI: "optimism-goerli",
  OPTIMISM_SEPOLIA: "optimism-sepolia",
  SEPOLIA: "sepolia",
  ZORA: "zora",
  ZORA_TESTNET: "zora-testnet",
  ZORA_SEPOLIA: "zora-sepolia",
  B3_SEPOLIA: "b3_sepolia",
  B3: "b3",
  // eslint-disable-next-line relay/no-future-added-value
  "%future added value": "",
}

export const CHAIN_IDENTIFIER_REVERSE_ENUM_MAPPING = reverse(
  CHAIN_IDENTIFIER_ENUM_MAPPING,
)
