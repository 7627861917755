/**
 * @generated SignedSource<<004b6ce264d8d7100dfed60979192e97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionsScrollerQuery$variables = {
  categories?: ReadonlyArray<string> | null;
  chains?: ReadonlyArray<| "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "B3" | "%future added value"> | null;
};
export type CollectionsScrollerQuery$data = {
  readonly trendingCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GlobalCollectionCard_data">;
      } | null;
    } | null>;
  };
};
export type CollectionsScrollerQuery = {
  response: CollectionsScrollerQuery$data;
  variables: CollectionsScrollerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categories"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chains"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories"
  },
  {
    "kind": "Variable",
    "name": "chains",
    "variableName": "chains"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionsScrollerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "trendingCollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "showContextMenu",
                        "value": false
                      },
                      {
                        "kind": "Literal",
                        "name": "showStats",
                        "value": false
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "GlobalCollectionCard_data"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionsScrollerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "trendingCollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verificationStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c20511805888911b78376419500a9fbe",
    "id": null,
    "metadata": {},
    "name": "CollectionsScrollerQuery",
    "operationKind": "query",
    "text": "query CollectionsScrollerQuery(\n  $categories: [CategorySlug!]\n  $chains: [ChainScalar!]\n) {\n  trendingCollections(first: 12, categories: $categories, chains: $chains) {\n    edges {\n      node {\n        ...GlobalCollectionCard_data_3f0GYR\n        id\n      }\n    }\n  }\n}\n\nfragment GlobalCollectionCard_data_3f0GYR on CollectionType {\n  logo\n  banner\n  name\n  verificationStatus\n  ...collection_url\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "c2614a99da09c7f43969fa94f2f0c002";

export default node;
