import { _FragmentRefs } from "relay-runtime"
import { WALLET_NAME } from "@/constants/wallet"
import type {
  Chain as ChainConfig,
  ChainIdentifier,
} from "@/hooks/useChains/types"
import { IToggle } from "@/lib/feature-flags/unleash/types"
import { addressesEqual } from "@/lib/helpers/address"
import { filter, flatMap } from "@/lib/helpers/array"
import { Promiseable } from "@/lib/helpers/promise"
import { UnreachableCaseError } from "../helpers/type"
import Provider from "./provider"
import { createAvalancheCoreProvider } from "./providers/avalancheProvider"
import { createBitKeepProvider } from "./providers/bitkeepProvider"
import { createBitskiProvider } from "./providers/bitskiProvider"
import BrowserWeb3Provider from "./providers/browserWeb3Provider"
import { createCoinbaseWalletProvider } from "./providers/coinbaseWalletProvider"
import { createFortmaticProvider } from "./providers/fortmaticProvider"
import KlaytnEvmProvider from "./providers/klaytnEvmProvider"
import { createLedgerConnectProvider } from "./providers/ledgerConnectProvider"
import { createOpenSeaWalletProvider } from "./providers/openSeaWalletProvider"
import { createPhantomEvmProvider } from "./providers/phantomEvmProvider"
import { PhantomSolanaProvider } from "./providers/phantomSolanaProvider"
import { createWalletConnectV2Provider } from "./providers/WalletConnect/walletConnectV2Provider"

export type Address = string

type UnsupportedChainIdentifier = null | undefined

export type AccountKey = {
  chain: ChainIdentifier | UnsupportedChainIdentifier
  address: Address
}

export type WalletAccountKey = AccountKey & {
  walletName: WALLET_NAME
  walletMetdata?: string
}

type OnProviderChangeListener = (providers: Provider[]) => unknown

type Initialization = () => Promiseable<Provider | undefined>

class Chain {
  private onProviderChangeListeners: OnProviderChangeListener[] = []
  private configs: ChainConfig[] = []
  private features: IToggle[] = []
  public providers: Provider[] = []

  public init = (configs: ChainConfig[], features: IToggle[]) => {
    this.configs = configs
    this.features = features
  }

  public isProviderInstalled = (name: WALLET_NAME): boolean => {
    return this.getProvider(name) !== undefined
  }

  public async addProvider(name: WALLET_NAME): Promise<Provider | undefined> {
    const provider = this.getProvider(name)
    if (provider) {
      return provider
    }
    const initProvider = this.getWalletProviderInitialization(name)
    if (!initProvider) {
      return undefined
    }
    return this.tryAddProvider(initProvider)
  }

  public async findProvider(
    address: string,
    walletName: WALLET_NAME,
  ): Promise<Provider | undefined> {
    for (const provider of this.providers) {
      if (provider.getName() !== walletName) {
        continue
      }

      const accounts = await provider.getAccounts()
      if (accounts.some(a => addressesEqual(a.address, address))) {
        return provider
      }
    }
    /**
     * We do not initialize WalletConnectV2 by default since it differs from other
     * providers that inject the eip1193 provider into the window object. Usually
     * we only initialize providers that we detect are present in the window object.
     * Since there is nothing to detect for WalletConnectV2, we initialize it on demand.
     */
    if (walletName === WALLET_NAME.WalletConnect) {
      return await this.addProvider(WALLET_NAME.WalletConnect)
    }
    return undefined
  }

  public async getAccounts(): Promise<ReadonlyArray<WalletAccountKey>> {
    const providerAccounts = await Promise.all(
      this.providers.map(provider => {
        return provider.getAccounts().catch(error => {
          console.info(
            `Error retrieving addresses for ${provider.getName()}:`,
            error,
          )
          return []
        })
      }),
    )
    return flatMap(providerAccounts, a => a)
  }

  public onProviderChange = (handler: OnProviderChangeListener) => {
    this.onProviderChangeListeners.push(handler)
    return () => {
      this.onProviderChangeListeners = this.onProviderChangeListeners.filter(
        h => h !== handler,
      )
    }
  }

  /**
   * Add all supported wallets that are detected in the document.
   */
  public async addDetectableProviders(): Promise<Provider[]> {
    const detectableProviders = this.getDetectableProviderInitializations(
      this.features,
    )
    const providers = filter(
      await Promise.all(
        detectableProviders.map(init => this.tryAddProvider(init)),
      ),
    )
    return providers
  }

  private async tryAddProvider(
    initProvider: Initialization,
  ): Promise<Provider | undefined> {
    try {
      const provider = await initProvider()
      if (!provider) {
        return undefined
      }

      const alreadyAddedProvider = this.providers.find(
        p => p.getName() === provider.getName(),
      )
      if (alreadyAddedProvider) {
        return alreadyAddedProvider
      }

      this.providers.push(provider)
      this.onProviderChangeListeners.forEach(handler =>
        handler([...this.providers]),
      )

      return provider
    } catch (error) {
      console.error("Failed to add a provider", error)
      return undefined
    }
  }

  private getProvider(name: WALLET_NAME): Provider | undefined {
    return this.providers.find(p => p.getName() === name)
  }

  private getWalletProviderInitialization = (
    name: WALLET_NAME,
  ): Initialization | undefined => {
    switch (name) {
      case WALLET_NAME.MetaMask:
      case WALLET_NAME.Trust:
      case WALLET_NAME.OperaTouch:
      case WALLET_NAME.Native:
      case WALLET_NAME.Zerion:
        return async () => await BrowserWeb3Provider.init(this.configs)
      case WALLET_NAME.Kaikas:
        return async () => await KlaytnEvmProvider.init(this.configs)
      case WALLET_NAME.CoinbaseWallet:
        return async () => await createCoinbaseWalletProvider(this.configs)
      case WALLET_NAME.Bitski:
        return async () => await createBitskiProvider(this.configs)
      case WALLET_NAME.Fortmatic:
        return async () => await createFortmaticProvider(this.configs)
      case WALLET_NAME.LedgerConnect:
        return async () => await createLedgerConnectProvider(this.configs)
      case WALLET_NAME.WalletConnect:
        return async () => await createWalletConnectV2Provider(this.configs)
      case WALLET_NAME.Phantom:
        return async () => await createPhantomEvmProvider(this.configs)
      case WALLET_NAME.BitKeep:
        return async () => await createBitKeepProvider(this.configs)
      case WALLET_NAME.Core:
        return async () => await createAvalancheCoreProvider(this.configs)
      case WALLET_NAME.OpenSeaWallet:
        return async () => await createOpenSeaWalletProvider(this.configs)
      default:
        throw new UnreachableCaseError(name)
    }
  }

  /**
   * Returns all provider initializations that detect injected provider in the document.
   */
  private getDetectableProviderInitializations = (_features: IToggle[]) => {
    const providers: Initialization[] = [
      () => BrowserWeb3Provider.init(this.configs),
      () => KlaytnEvmProvider.init(this.configs),
      () => createPhantomEvmProvider(this.configs),
      PhantomSolanaProvider.init,
      () => createBitKeepProvider(this.configs),
      () => createAvalancheCoreProvider(this.configs),
    ]

    /* EXAMPLE:
     *
     * if (isWalletEnabled(features)) {
     *   providers.push(() => initializeWallet(this.configs))
     * }
     */

    return providers
  }
}

const chain = new Chain()

export default chain
