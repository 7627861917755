import { datadogRum } from "@datadog/browser-rum"
import { ethers } from "ethers"
import { noop } from "lodash"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import { detectProvider } from "./detect"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  ExternalProvider,
  Web3Provider,
} from "./web3EvmProvider"

type BrowserWeb3ProviderT = ExternalProvider & {
  // This field gets populated when there are multiple injected browser providers i.e MetaMask and Coinbase Wallet
  // https://ethereum.stackexchange.com/questions/111605/how-can-i-connect-my-dapp-to-metamask-if-coinbase-wallet-injects-another-ethere
  providers?: (ExternalProvider & {
    isMetaMask?: boolean
    isCoinbaseWallet?: boolean
    isUniswapWallet?: boolean
    isZerion?: boolean
  })[]
}

declare global {
  interface Window {
    ethereum?: BrowserWeb3ProviderT
  }
}

const detectEthereumProvider = (timeout = 3000) => {
  return detectProvider({
    timeout,
    key: "ethereum",
    isInstalled: BrowserWeb3Provider.isInstalled,
    initializationEvent: "ethereum#initialized",
  })
}

class ExtensionUnresponsiveError extends Error {
  constructor(walletName: WALLET_NAME) {
    super(`${walletName} wallet extension is unresponsive`)
  }
}

// For all intents and purposes, this provider handles MetaMask ONLY. CoinbaseWallet is handled by the WalletLink provider
export default class BrowserWeb3Provider extends Web3EvmProvider {
  provider: Web3Provider

  constructor(provider: Web3Provider, chains: Chain[]) {
    super(chains)
    this.provider = provider
  }

  public static init = async (chains: Chain[]) => {
    const detectedProvider = await detectEthereumProvider()
    // We either find metamask or just fallback to the given provider
    const provider =
      detectedProvider?.providers?.find(provider => provider.isMetaMask) ??
      detectedProvider

    if (!provider) {
      return undefined
    }

    const assertExtensionIsResponsive = async (
      walletName: WALLET_NAME,
      retries = 4,
    ): Promise<void> => {
      try {
        await Promise.race([
          provider.request?.({ method: "eth_accounts" }),
          new Promise((_, reject) => {
            setTimeout(
              () => reject(new ExtensionUnresponsiveError(walletName)),
              2_000,
            )
          }),
        ])
      } catch (error) {
        if (error instanceof ExtensionUnresponsiveError) {
          if (retries > 0) {
            console.error(
              `Responsiveness check failed. ${retries - 1} retries left.`,
            )
            return assertExtensionIsResponsive(walletName, retries - 1)
          } else {
            console.error(`Responsiveness check failed. No retries left.`)
          }
        } else {
          console.error(`Responsiveness check failed.`, error)
        }
        throw error
      }
    }

    if (
      provider.isMetaMask &&
      // TODO: temporary workaround for uniswap wallet.
      // can remove if we have a separate uniswap wallet connection option or we no longer need the responsiveness check
      !(provider as BrowserWeb3ProviderT & { isUniswapWallet: boolean })
        .isUniswapWallet
    ) {
      try {
        await assertExtensionIsResponsive(WALLET_NAME.MetaMask)
      } catch (error) {
        datadogRum.addError(error, {
          level: "warning",
          walletName: WALLET_NAME.MetaMask,
        })
        return undefined
      }
    }

    return new BrowserWeb3Provider(
      new ethers.providers.Web3Provider(provider, ETHERS_WEB3_PROVIDER_NETWORK),
      chains,
    )
  }

  public static isInstalled = (
    wallet: BrowserWeb3ProviderT | undefined,
  ): wallet is BrowserWeb3ProviderT => {
    return wallet !== undefined
  }

  disconnect = noop

  public getName(): WALLET_NAME {
    const web3Provider = this.provider.provider as {
      isMetaMask?: boolean
      isTrust?: boolean
      isCoinbaseWallet?: boolean
      isBitKeep?: boolean
      isZerion?: boolean
    }

    if (web3Provider.isMetaMask) {
      return WALLET_NAME.MetaMask
    }
    if (web3Provider.isTrust) {
      return WALLET_NAME.Trust
    }
    if (web3Provider.isCoinbaseWallet) {
      return WALLET_NAME.CoinbaseWallet
    }
    if (web3Provider.isBitKeep) {
      return WALLET_NAME.BitKeep
    }
    if (web3Provider.isZerion) {
      return WALLET_NAME.Zerion
    }

    return WALLET_NAME.Native
  }
}
