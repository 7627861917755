/**
 * @generated SignedSource<<b09109b72934f3d862d1726145468127>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderSide = "ASK" | "BID" | "%future added value";
export type OrderTypeEnum = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrdersHeaderData_orders$data = ReadonlyArray<{
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly item: {
    readonly __typename: "AssetBundleToBeCreatedType";
    readonly assetQuantitiesToBeCreated: ReadonlyArray<{
      readonly asset: {
        readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
      };
    }>;
  } | {
    readonly __typename: "AssetBundleType";
    readonly assetQuantities: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
          };
        } | null;
      } | null>;
    };
  } | {
    readonly __typename: "AssetQuantityDataType";
    readonly asset: {
      readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly orderCriteria: {
    readonly collection: {
      readonly representativeAsset: {
        readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
      } | null;
    };
  } | null;
  readonly orderType: OrderTypeEnum;
  readonly side: OrderSide;
  readonly " $fragmentType": "OrdersHeaderData_orders";
}>;
export type OrdersHeaderData_orders$key = ReadonlyArray<{
  readonly " $data"?: OrdersHeaderData_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrdersHeaderData_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "StackedAssetMedia_assets"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssetType",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OrdersHeaderData_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "AssetQuantityDataType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 20
                }
              ],
              "concreteType": "AssetQuantityTypeConnection",
              "kind": "LinkedField",
              "name": "assetQuantities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "assetQuantities(first:20)"
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "assetQuantitiesToBeCreated",
              "args": null,
              "concreteType": "AssetQuantityDataType",
              "kind": "LinkedField",
              "name": "assetQuantities",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "AssetBundleToBeCreatedType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderCriteriaType",
      "kind": "LinkedField",
      "name": "orderCriteria",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetType",
              "kind": "LinkedField",
              "name": "representativeAsset",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    }
  ],
  "type": "OrderDataType",
  "abstractKey": null
};
})();

(node as any).hash = "a07b352be1c88fcfad6b0ac7a0531912";

export default node;
