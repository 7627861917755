/**
 * @generated SignedSource<<2085806cf27a68457fe8398422f1b84b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BulkFulfillOrdersAction_data$data = ReadonlyArray<{
  readonly __typename: "BulkFulfillOrdersActionType";
  readonly maxOrdersToFill: string | null;
  readonly ordersToFill: ReadonlyArray<{
    readonly itemFillAmount: string;
    readonly orderData: {
      readonly chain: {
        readonly identifier: ChainIdentifier;
      };
      readonly item: {
        readonly asset?: {
          readonly collection: {
            readonly slug: string;
            readonly verificationStatus: VerificationStatus;
          };
          readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
        };
        readonly assetQuantities?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly asset: {
                readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
              };
            } | null;
          } | null>;
        };
        readonly collection?: {
          readonly slug: string;
          readonly verificationStatus: VerificationStatus;
        } | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_ordersData">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"readOrderDataToFillPrices_orderDataToFill" | "useTotalPriceOrderDataToFill_ordersToFill">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_bulk_fulfill_orders">;
  readonly " $fragmentType": "BulkFulfillOrdersAction_data";
}>;
export type BulkFulfillOrdersAction_data$key = ReadonlyArray<{
  readonly " $data"?: BulkFulfillOrdersAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkFulfillOrdersAction_data">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemFillAmount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionType",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "StackedAssetMedia_assets"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BulkFulfillOrdersAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxOrdersToFill",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderToFillType",
      "kind": "LinkedField",
      "name": "ordersToFill",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetType",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "AssetQuantityDataType",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 30
                        }
                      ],
                      "concreteType": "AssetQuantityTypeConnection",
                      "kind": "LinkedField",
                      "name": "assetQuantities",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetQuantityTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AssetQuantityType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AssetType",
                                  "kind": "LinkedField",
                                  "name": "asset",
                                  "plural": false,
                                  "selections": [
                                    (v3/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "assetQuantities(first:30)"
                    }
                  ],
                  "type": "AssetBundleType",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useTotalItems_ordersData"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTotalPriceOrderDataToFill_ordersToFill"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "readOrderDataToFillPrices_orderDataToFill",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderDataType",
              "kind": "LinkedField",
              "name": "orderData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "perUnitPrice",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "usd",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unit",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentAssetType",
                  "kind": "LinkedField",
                  "name": "payment",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "TokenPricePayment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "symbol",
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_bulk_fulfill_orders",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionSubmissionDataType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useHandleBlockchainActions_transaction",
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useTransaction_transaction",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "source",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "destination",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "data",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderToFillType",
          "kind": "LinkedField",
          "name": "ordersToFill",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderDataType",
              "kind": "LinkedField",
              "name": "orderData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "openedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "BulkFulfillOrdersActionType",
  "abstractKey": null
};
})();

(node as any).hash = "0e1aaaefc94ad39083afb7223ecb89ee";

export default node;
